import { takeEvery, put, call, cancelled } from 'redux-saga/effects';
import {
  VEHICLE_INCOMING_DETAILS_REQUEST,
  vehicleIncomingDetailsSuccess,
  vehicleIncomingDetailsFail
} from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
import { getVehicleDetails } from 'modules/DetailsVehicleIncoming/services';

export function* workVehicleIncomingDetailsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const response = yield call(getVehicleDetails, {
      params: payload.params,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(vehicleIncomingDetailsSuccess(response.data.data));
    } else {
      yield put(vehicleIncomingDetailsFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(vehicleIncomingDetailsFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchVehicleIncomingDetailsRequest() {
  yield takeEvery(VEHICLE_INCOMING_DETAILS_REQUEST, workVehicleIncomingDetailsRequest);
}

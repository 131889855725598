import { takeEvery, put, call } from 'redux-saga/effects';
import {
  VEHICLE_HISTORY_STATUS_REQUEST,
  vehicleHistoryStatusSuccess,
  vehicleHistoryStatusFail
} from '../actions';
import { responseOK } from 'utils';
import { getTransferActions } from 'modules/Transfer/services';

export function* workVehicleHistoryStatusRequest(action) {
  try {
    const { payload } = action;
    const response = yield call(getTransferActions, { params: payload.requestData });
    if (responseOK(response)) {
      yield put(vehicleHistoryStatusSuccess(response.data?.destination.statusLog));
    } else {
      yield put(vehicleHistoryStatusFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(vehicleHistoryStatusFail('FAIL'));
  }
}

export function* watchVehicleHistoryStatusRequest() {
  yield takeEvery(VEHICLE_HISTORY_STATUS_REQUEST, workVehicleHistoryStatusRequest);
}

import { takeEvery, put, call } from 'redux-saga/effects';
import * as action from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
import * as service from '../../services';

export function* workUsersLogRequest(actions) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = actions;
    const { onSucces, onFail } = payload;
    const response = yield call(service.getEventLog, {
      data: payload.params,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(action.usersLogSuccess(response.data.data));
      if (onSucces) {
        yield onSucces(response.data.data);
      }
    } else {
      yield put(action.usersLogFail());
      if (onFail) {
        yield onFail();
      }
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(action.usersLogFail());
  }
}

export function* watchUsersLogRequest() {
  yield takeEvery(action.USERS_LOG_REQUEST, workUsersLogRequest);
}

import {
  watchCalculateCompensationInfoRequest,
  watchCompensationCalcPostRequest,
  watchCompensationSendRequest
} from './calculateCompensation';

import {
  watchValidationCompensationRequest,
  watchValidationCommentsRequest
} from './setValidationComment';

export {
  watchCalculateCompensationInfoRequest,
  watchCompensationCalcPostRequest,
  watchCompensationSendRequest,
  watchValidationCompensationRequest,
  watchValidationCommentsRequest
};

// Actions
import {
  VEHICLE_INCOMING_DETAILS_REQUEST,
  VEHICLE_INCOMING_DETAILS_SUCCESS,
  VEHICLE_INCOMING_DETAILS_FAIL,
  VEHICLE_HISTORY_STATUS_REQUEST,
  VEHICLE_HISTORY_STATUS_SUCCESS,
  VEHICLE_HISTORY_STATUS_FAIL
} from '../actions/index';

// Default state
const vehicleIncomingDetailsReducerDefaultState = {};

export default (state = vehicleIncomingDetailsReducerDefaultState, action) => {
  switch (action.type) {
    case VEHICLE_INCOMING_DETAILS_REQUEST:
      return { ...state, isLoading: true };
    case VEHICLE_INCOMING_DETAILS_SUCCESS:
      return { ...state, isLoading: false, data: action?.payload };
    case VEHICLE_INCOMING_DETAILS_FAIL:
      return { ...state, isLoading: false };
    case VEHICLE_HISTORY_STATUS_REQUEST:
      return {
        ...state,
        historyStatus: {
          isLoading: true
        }
      };
    case VEHICLE_HISTORY_STATUS_SUCCESS:
      return {
        ...state,
        historyStatus: {
          isLoading: false,
          data: action?.payload
        }
      };
    case VEHICLE_HISTORY_STATUS_FAIL:
      return {
        ...state,
        historyStatus: {
          isLoading: false
        }
      };
    default:
      return state;
  }
};

import { takeEvery, put, call } from 'redux-saga/effects';
import * as action from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
import * as service from '../../services';

export function* workOperationsLogRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(service.getOperationsLog, {
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(action.operationsLogSuccess(response.data.data));
    } else {
      yield put(action.operationsLogFail());
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(action.operationsLogFail());
  }
}

export function* watchOperationsLogRequest() {
  yield takeEvery(action.OPERATIONS_LOG_REQUEST, workOperationsLogRequest);
}

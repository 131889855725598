import { takeEvery, put, all, call, cancelled, delay } from 'redux-saga/effects';
import {
  COMPENSATION_INFO_REQUEST,
  compensationInfoSuccess,
  compensationInfoClear,
  COMPENSATION_POST_REQUEST,
  compensationPostSuccess,
  compensationPostFail,
  COMPENSATION_SEND_REQUEST,
  compensationSendSuccess,
  compensationSendFail,
  compensationSendRequest
} from '../actions';
import { addAnIdToElements } from '../../utils';
import { sendNotification } from 'helpers/helperNotifications';
import instance from 'services/request';
import {
  getCompensationResource,
  interactWithCompensation,
  serviceURL,
  sendCompensation
} from '../../services';
import { responseOK } from 'utils';
import { requestDownloadFile } from 'redux/actions/downloadFile';

export function* workCalculateCompensation(action) {
  try {
    const {
      payload,
      payload: {
        requestData: { caseId },
        resourceName
      }
    } = action;

    const response = yield call(getCompensationResource, {
      caseId,
      resource: resourceName ?? 'Detail'
    });

    if (typeof response !== 'undefined' && response.status === 200) {
      const responseData = response?.data?.data;
      if (payload?.setFormData) {
        payload.setFormData({
          productInfo: response.data.data.productInfo,
          vehicleInfo: response.data.data.vehicleInfo,
          insuredInfo: {
            ...response.data.data.insuredInfo,
            report: response?.data?.data?.vehicleInfo?.report
          },
          calculationData: {
            ...response.data.data.calculationData,
            covers: addAnIdToElements(response.data?.data?.calculationData?.covers ?? []),
            deductions: addAnIdToElements(response.data?.data.calculationData?.deductions ?? [])
          }
        });
      }
      //debugger;
      yield put(
        compensationInfoSuccess({
          ...responseData,
          insuredInfo: {
            ...responseData?.insuredInfo,
            report: responseData?.vehicleInfo?.report,
            vehicleSerial: responseData?.vehicleInfo?.vehicleSerial
          }
          // validationComment: {
          //   compensationId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
          //   comments: 'string',
          //   registered: '2021-03-26T17:00:22.424Z',
          //   userName: 'string',
          //   compensationStatus: 'string',
          //   compensationStatusId: 0,
          //   validationStatus: 'string',
          //   validationStatusId: 0
          // }
        })
      );
    } else {
      yield put(compensationInfoClear({ reason: 'ERROR' }));
    }
  } catch (error) {
    yield put(compensationInfoClear({ reason: 'ERROR' }));

    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(compensationInfoClear());
  }
}

export function* workCompensationCalcPostRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const {
      requestData,
      redirectConfig,
      requestConfig: { downloadOnSucess, sendTableByEmail, method, tableFileName }
    } = payload;

    const response = yield call(interactWithCompensation, {
      data: requestData,
      cancelToken: source,
      method
    });

    if (responseOK(response)) {
      yield put(compensationInfoClear({ reason: 'RESET' }));
      sendNotification('success', response.data.message);
      yield delay(500);

      if (downloadOnSucess) {
        if (tableFileName && tableFileName !== '') {
          yield put(
            requestDownloadFile({
              name: `${tableFileName}.xlsx`,
              url: `${serviceURL}Reports/${requestData?.caseId}`,
              successMessage: 'La tabla se descargo correctamente'
            })
          );
        } else {
          const responseFileName = yield call(getCompensationResource, {
            caseId: requestData.caseId,
            resource: 'Detail'
          });

          if (responseOK(responseFileName)) {
            yield put(
              requestDownloadFile({
                name: `${responseFileName?.data?.data?.tableFileName}`,
                url: `${serviceURL}Reports/${requestData?.caseId}`,
                successMessage: 'La tabla se descargo correctamente'
              })
            );
          }
        }
      }

      if (sendTableByEmail) {
        yield put(compensationSendRequest({ requestData: { caseId: requestData.caseId } }));
      }

      yield put(compensationPostSuccess());
      yield delay(200);
      redirectConfig.push(redirectConfig.path);
    } else {
      yield put(compensationPostFail());
    }
  } catch (error) {
    yield put(compensationPostFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* workCompensationSendRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { requestData, onSuccess } = payload;

    const response = yield call(sendCompensation, { params: requestData, cancelToken: source });
    if (responseOK(response)) {
      yield put(compensationSendSuccess());
      sendNotification('success', response.data.message);
      if (onSuccess) {
        yield onSuccess();
      }
    } else {
      yield put(compensationSendFail());
    }
  } catch (error) {
    yield put(compensationSendFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchCalculateCompensationInfoRequest() {
  yield all([takeEvery(COMPENSATION_INFO_REQUEST, workCalculateCompensation)]);
}

export function* watchCompensationCalcPostRequest() {
  yield all([takeEvery(COMPENSATION_POST_REQUEST, workCompensationCalcPostRequest)]);
}

export function* watchCompensationSendRequest() {
  yield all([takeEvery(COMPENSATION_SEND_REQUEST, workCompensationSendRequest)]);
}

export const VEHICLE_INCOMING_DETAILS_REQUEST = 'VEHICLE_INCOMING_DETAILS_REQUEST';
export const VEHICLE_INCOMING_DETAILS_SUCCESS = 'VEHICLE_INCOMING_DETAILS_SUCCESS';
export const VEHICLE_INCOMING_DETAILS_FAIL = 'VEHICLE_INCOMING_DETAILS_FAIL';

export const VEHICLE_HISTORY_STATUS_REQUEST = 'VEHICLE_HISTORY_STATUS_REQUEST';
export const VEHICLE_HISTORY_STATUS_SUCCESS = 'VEHICLE_HISTORY_STATUS_SUCCESS';
export const VEHICLE_HISTORY_STATUS_FAIL = 'VEHICLE_HISTORY_STATUS_FAIL';

export const vehicleIncomingDetailsRequest = payload => ({
  type: VEHICLE_INCOMING_DETAILS_REQUEST,
  payload
});

export const vehicleIncomingDetailsSuccess = payload => ({
  type: VEHICLE_INCOMING_DETAILS_SUCCESS,
  payload
});

export const vehicleIncomingDetailsFail = () => ({
  type: VEHICLE_INCOMING_DETAILS_FAIL
});

export const vehicleHistoryStatusRequest = payload => ({
  type: VEHICLE_HISTORY_STATUS_REQUEST,
  payload
});

export const vehicleHistoryStatusSuccess = payload => ({
  type: VEHICLE_HISTORY_STATUS_SUCCESS,
  payload
});

export const vehicleHistoryStatusFail = () => ({
  type: VEHICLE_HISTORY_STATUS_FAIL
});

import request from 'services/request';

export const serviceURL = 'Compensation/Api/v1/Compensations/';

export const serviceValidateURL = 'ValidateCompensation/';

export async function getCompensationResource({ caseId, resource = 'detail' }) {
  return request(`${serviceURL}${resource}/${caseId}`, {
    method: 'GET'
  });
}

export async function interactWithCompensation({ data, method = 'POST' }) {
  return request(`${serviceURL}`, {
    method,
    data
  });
}

export async function sendCompensation({ params }) {
  return request(`${serviceURL}Send?caseId=${params.caseId}`, {
    method: 'POST'
  });
}

export async function validateCompensation({ data, method = 'POST', cancelToken }) {
  return request(`${serviceURL}${serviceValidateURL}`, {
    data,
    method,
    cancelToken
  });
}

export async function validationComments({ caseId, cancelToken }) {
  return request(`${serviceURL}ValidationComments/${caseId}`, {
    method: 'GET',
    cancelToken
  });
}

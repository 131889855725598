import { takeEvery, put, cancelled, delay, call } from 'redux-saga/effects';
import { sendNotification } from 'helpers/helperNotifications';
import {
  VALIDATE_REQUEST,
  validateSuccess,
  validateFail,
  compensationSendRequest,
  VALIDATION_COMMENTS_REQUEST,
  validationCommentsSuccess,
  validationCommentsFail
} from '../actions';
import instance from 'services/request';
import { responseOK } from 'utils';
// import { MSG_001 } from 'utils/messages';
import { validateCompensation, validationComments } from '../../services/index';

function* workValidationCompensationRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    // console.log('payload :>> ', payload);

    const response = yield call(validateCompensation, {
      data: payload.requestData,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      sendNotification('success', response.data.message);
      yield put(validateSuccess(response.data.data));
      yield delay(100);
      if (payload.requestData.Validate) {
        yield put(compensationSendRequest({ requestData: { caseId: payload.requestData.CaseId } }));
        if (payload?.onSuccess) {
          yield delay(400);
          yield payload.onSuccess();
        }
      }
    } else {
      yield put(validateFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(validateFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchValidationCompensationRequest() {
  yield takeEvery(VALIDATE_REQUEST, workValidationCompensationRequest);
}

function* workValidationCommentsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const response = yield call(validationComments, {
      caseId: payload.caseId,
      cancelToken: source.token
    });
    if (responseOK(response)) {
      yield put(validationCommentsSuccess(response.data.data));
    } else {
      yield put(validationCommentsFail('FAIL'));
    }
  } catch (error) {
    console.error('[Redux-Saga-Error-AutoOnline]', error);
    yield put(validationCommentsFail('FAIL'));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
      yield put(validationCommentsFail('FAIL'));
    }
  }
}

export function* watchValidationCommentsRequest() {
  yield takeEvery(VALIDATION_COMMENTS_REQUEST, workValidationCommentsRequest);
}

import { sendNotification } from 'helpers/helperNotifications';
import { generateUUID } from 'utils';
import { Modal } from 'antd';
import { buttonsProps } from 'helpers/helperModals';
import i18next from 'i18next';

export const addAnIdToElements = collection => {
  let newCollection;
  if (collection.length > 0) {
    newCollection = collection.map(element => {
      return { ...element, id: generateUUID() };
    });
  } else {
    newCollection = collection;
  }
  return newCollection;
};

export const preloadDatOnForm = (savedData, form) => {
  if (savedData) {
    form.setFieldsValue({ ...savedData });
  }
};

const collectionsConfig = {
  covers: {
    collectionLabel: 'coberturas',
    elementLabel: 'cobertura',
    max: 15
  },
  deductions: { collectionLabel: 'deducciones', elementLabel: 'deducción', max: 10 }
};

export const createCalculationDynamicField = args => {
  const { formData, collectionName, onCreate } = args;
  if (formData?.calculationData[collectionName].length < collectionsConfig[collectionName]?.max) {
    const newElement = {
      name: undefined,
      id: generateUUID(),
      value: 0
    };
    onCreate({
      ...formData,
      calculationData: {
        ...formData.calculationData,
        [collectionName]: [...formData.calculationData[collectionName], newElement]
      }
    });
  } else {
    sendNotification(
      'info',
      `Se ha alcanzado el número máximo de ${collectionsConfig[collectionName].collectionLabel} para agregar`
    );
  }
};

export const updateCalculationDynamicField = args => {
  const { formData, collectionName, id, onUpdate, newField } = args;
  let newCollection = formData.calculationData[collectionName].map(item => {
    if (item?.id === id) {
      return newField;
    } else {
      return item;
    }
  });
  onUpdate({
    ...formData,
    calculationData: {
      ...formData.calculationData,
      [collectionName]: [...newCollection]
    }
  });
};

export const deleteCalculationDynamicField = args => {
  const { formData, collectionName, id, onDelete } = args;
  Modal.confirm({
    title: `¿Esta seguro que desea eliminar la ${collectionsConfig[collectionName]?.elementLabel}?`,
    okText: i18next.t('system.acept'),
    cancelText: i18next.t('system.cancel'),
    okButtonProps: buttonsProps,
    cancelButtonProps: buttonsProps,
    /* istanbul ignore next */
    onOk() {
      const newCollection = formData.calculationData[collectionName].filter(
        element => element.id !== id
      );
      onDelete({
        ...formData,
        calculationData: { ...formData.calculationData, [collectionName]: newCollection }
      });
    }
  });
};

export const updateCalculationField = args => {
  const { formData, onUpdate, inputKey, value } = args;
  onUpdate({
    ...formData,
    calculationData: {
      ...formData?.calculationData,
      [inputKey]: value
    }
  });
};

import request from 'services/request';
import { buildUrl } from 'modules/ReportsInventoryEntries/utils';
const usersLogURl = 'Users/Api/v1/Users/Log';
const opLogURl = 'Users/Api/v1/Users/Log/Operations';

export async function getEventLog({ data, cancelToken }) {
  return request(`${usersLogURl}?${buildUrl(data)}`, {
    method: 'GET',
    cancelToken
  });
}

export async function getOperationsLog({ cancelToken }) {
  return request(opLogURl, {
    method: 'GET',
    cancelToken
  });
}

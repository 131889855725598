import {
  COMPENSATION_INFO_REQUEST,
  COMPENSATION_INFO_SUCCESS,
  COMPENSATION_INFO_CLEAR,
  COMPENSATION_POST_REQUEST,
  COMPENSATION_POST_SUCCESS,
  COMPENSATION_POST_FAIL,
  COMPENSATION_SEND_REQUEST,
  COMPENSATION_SEND_SUCCESS,
  COMPENSATION_SEND_FAIL,
  VALIDATE_REQUEST,
  VALIDATE_SUCCESS,
  VALIDATE_FAIL,
  VALIDATION_COMMENTS_REQUEST,
  VALIDATION_COMMENTS_SUCCESS,
  VALIDATION_COMMENTS_FAIL
} from '../actions/index';

function transferDetail(state = {}, action) {
  const { type } = action;

  switch (type) {
    case COMPENSATION_INFO_REQUEST:
      return {
        ...state,
        isLoading: true
      };

    case COMPENSATION_INFO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action?.payload
      };

    case COMPENSATION_INFO_CLEAR:
      return {
        ...state,
        data: {},
        isLoading: false
      };

    case COMPENSATION_POST_REQUEST:
      return {
        ...state,
        isPosting: true
      };

    case COMPENSATION_POST_FAIL:
    case COMPENSATION_POST_SUCCESS:
      return {
        ...state,
        isPosting: false
      };

    case COMPENSATION_SEND_REQUEST:
      return {
        ...state,
        isSending: true
      };

    case COMPENSATION_SEND_SUCCESS:
    case COMPENSATION_SEND_FAIL:
      return {
        ...state,
        isSending: false
      };
    case VALIDATE_REQUEST:
      return {
        ...state,
        validateComment: {
          isLoading: true
        }
      };
    case VALIDATE_SUCCESS:
      return {
        ...state,
        validateComment: {
          isLoading: false,
          data: action?.payload
        }
      };
    case VALIDATE_FAIL:
      return {
        ...state,
        validateComment: {
          isLoading: false
        }
      };
    case VALIDATION_COMMENTS_REQUEST:
      return {
        ...state,
        validationComments: {
          isLoading: true
        }
      };
    case VALIDATION_COMMENTS_SUCCESS:
      return {
        ...state,
        validationComments: {
          isLoading: true,
          data: action?.payload
        }
      };
    case VALIDATION_COMMENTS_FAIL:
      return {
        ...state,
        validationComments: {
          isLoading: false
        }
      };

    default:
      return state;
  }
}

export default transferDetail;

import * as logs from '../actions';

const defaultState = {};

export default (state = defaultState, action) => {
  switch (action.type) {
    case logs.USERS_LOG_REQUEST:
      return {
        ...state,
        usersLog: {
          isLoading: true
        }
      };
    case logs.USERS_LOG_SUCCESS:
      return {
        ...state,
        usersLog: {
          isLoading: false,
          data: action?.payload
        }
      };
    case logs.USERS_LOG_FAIL:
      return {
        ...state,
        usersLog: {
          isLoading: false
        }
      };
    case logs.OPERATIONS_LOG_REQUEST:
      return {
        ...state,
        operationsLog: {
          isLoading: true
        }
      };
    case logs.OPERATIONS_LOG_SUCCESS:
      return {
        ...state,
        operationsLog: {
          isLoading: false,
          data: action?.payload
        }
      };
    case logs.OPERATIONS_LOG_FAIL:
      return {
        ...state,
        operationsLog: {
          isLoading: false
        }
      };
    default:
      return state;
  }
};

export const OPERATIONS_LOG_REQUEST = 'OPERATIONS_LOG_REQUEST';
export const OPERATIONS_LOG_SUCCESS = 'OPERATIONS_LOG_SUCCESS';
export const OPERATIONS_LOG_FAIL = 'OPERATIONS_LOG_FAIL';

export const USERS_LOG_REQUEST = 'USERS_LOG_REQUEST';
export const USERS_LOG_SUCCESS = 'USERS_LOG_SUCCESS';
export const USERS_LOG_FAIL = 'USERS_LOG_FAIL';

export const operationsLogRequest = () => ({
  type: OPERATIONS_LOG_REQUEST
});

export const operationsLogSuccess = payload => ({
  type: OPERATIONS_LOG_SUCCESS,
  payload
});

export const operationsLogFail = () => ({
  type: OPERATIONS_LOG_FAIL
});

export const usersLogRequest = payload => ({
  type: USERS_LOG_REQUEST,
  payload
});

export const usersLogSuccess = payload => ({
  type: USERS_LOG_SUCCESS,
  payload
});

export const usersLogFail = () => ({
  type: USERS_LOG_FAIL
});

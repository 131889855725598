import request from 'services/request';
const serviceURL = 'Inventories/Api/v1/Inventories/incomingCaseDetail/';

export const getVehicleDetails = async ({ params, cancelToken }) => {
  const { transferId } = params;
  return request(`${serviceURL}${transferId}`, {
    method: 'GET',
    cancelToken
  });
};

export const COMPENSATION_INFO_REQUEST = 'COMPENSATION_INFO_REQUEST/COMPENSATION';
export const COMPENSATION_INFO_SUCCESS = 'COMPENSATION_INFO_SUCCESS/COMPENSATION';
export const COMPENSATION_INFO_CLEAR = 'COMPENSATION_INFO_CLEAR/COMPENSATION';

export const COMPENSATION_POST_REQUEST = 'COMPENSATION_POST_REQUEST';
export const COMPENSATION_POST_SUCCESS = 'COMPENSATION_POST_SUCCESS';
export const COMPENSATION_POST_FAIL = 'COMPENSATION_POST_FAIL';

export const COMPENSATION_SEND_REQUEST = 'COMPENSATION_SEND_REQUEST';
export const COMPENSATION_SEND_SUCCESS = 'COMPENSATION_SEND_SUCCESS';
export const COMPENSATION_SEND_FAIL = 'COMPENSATION_SEND_FAIL';

export const VALIDATE_REQUEST = 'VALIDATE_REQUEST';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const VALIDATE_FAIL = 'VALIDATE_FAIL';

export const VALIDATION_COMMENTS_REQUEST = 'VALIDATION_COMMENTS_REQUEST';
export const VALIDATION_COMMENTS_SUCCESS = 'VALIDATION_COMMENTS_SUCCESS';
export const VALIDATION_COMMENTS_FAIL = 'VALIDATION_COMMENTS_FAIL';

export function compensationSendRequest(payload) {
  return {
    type: COMPENSATION_SEND_REQUEST,
    payload: payload
  };
}

export function compensationSendSuccess(payload) {
  return {
    type: COMPENSATION_SEND_SUCCESS,
    payload: payload
  };
}

export function compensationSendFail() {
  return {
    type: COMPENSATION_SEND_FAIL
  };
}

export function compensationInfoRequest(payload) {
  return {
    type: COMPENSATION_INFO_REQUEST,
    payload: payload
  };
}

export function compensationInfoSuccess(payload) {
  return {
    type: COMPENSATION_INFO_SUCCESS,
    payload: payload
  };
}

export function compensationInfoClear() {
  return {
    type: COMPENSATION_INFO_CLEAR
  };
}

export function compensationPostRequest(payload) {
  return {
    type: COMPENSATION_POST_REQUEST,
    payload
  };
}

export function compensationPostSuccess(payload) {
  return {
    type: COMPENSATION_POST_SUCCESS,
    payload
  };
}

export function compensationPostFail() {
  return {
    type: COMPENSATION_POST_FAIL
  };
}

export const validateRequest = payload => ({
  type: VALIDATE_REQUEST,
  payload
});

export const validateSuccess = payload => ({
  type: VALIDATE_SUCCESS,
  payload
});

export const validateFail = () => ({
  type: VALIDATE_FAIL
});

export const validationCommentsRequest = payload => ({
  type: VALIDATION_COMMENTS_REQUEST,
  payload
});

export const validationCommentsSuccess = payload => ({
  type: VALIDATION_COMMENTS_SUCCESS,
  payload
});

export const validationCommentsFail = () => ({
  type: VALIDATION_COMMENTS_FAIL
});
